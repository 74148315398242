<template>
    <div>finalising login</div>
</template>
<script>
import auth0 from "auth0-js"
import router from "@/router"
export default {
    created(){                        
        /*
        let webAuth = new webAuth(
            {}
        );
        webAuth.popup.callback();                
        this.$store.dispatch('auth0HandleAuthentication')        
        */
    }
}
</script>